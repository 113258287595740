<template lang="pug">
v-app
  v-main
    router-view
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from './stores/useAuthStore'

export default {
  name: 'App',
  computed: {
    ...mapStores(useAuthStore),
  },
  watch: {
    'authStore.user'() {
      this.checkAuth()
    },
    $route() {
      this.checkAuth()
    },
  },
  methods: {
    checkAuth() {
      const { isPublic, allowedGroups } = this.$route.meta
      const { user } = this.authStore
      const routeName = this.$route.name

      const hasPerm =
        !allowedGroups || this.authStore.hasAnyGroup(allowedGroups)

      switch (true) {
        // Vista login estando ya logueado
        case user && routeName === 'login':
          return this.$router.push({ name: 'dashboard' }).catch(() => {})

        // Vista pública sin requisitos
        case isPublic:
          return

        // No hay usuario logueado
        case !user:
          return this.$router.push({ name: 'login' }).catch(() => {})

        // No autorizado
        case !hasPerm:
          return this.$router.push({ name: 'dashboard' }).catch(() => {})
      }
    },
  },
  created() {
    this.authStore.restoreUserFromStorage()
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@/styles/global.scss';
</style>
