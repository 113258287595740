import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import es from 'vuetify/lib/locale/es'
import light from './theme'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

export default new Vuetify({
  theme: {
    themes: { light },
    options: { customProperties: true },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },
})
