// Componente vacío con un router-view de paso
// const PassThru = { render: (c) => c('router-view') }

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        // redirect: { name: '' },
        component: () => import('@/views/HomeView.vue'),
        meta: {
          breadcrumb: 'Dashboard',
          dashboardIcon: 'home',
          isNavItem: true,
          navText: 'Dashboard',
        },
      },
      {
        path: 'chat',
        name: 'chat',
        // redirect: { name: '' },
        component: () => import('@/views/ChatView.vue'),
        meta: {
          breadcrumb: 'Chat',
          dashboardIcon: 'question_answer',
          isNavItem: true,
          navText: 'Chat',
          allowedGroups: ['Agente Whatsapp', 'Supervisor Whatsapp'],
        },
      },
      {
        path: 'contacts',
        name: 'contacts',
        // redirect: { name: '' },
        component: () => import('@/views/ContactsView.vue'),
        meta: {
          breadcrumb: 'Contactos',
          dashboardIcon: 'people',
          isNavItem: true,
          navText: 'Contactos',
          allowedGroups: ['Agente Whatsapp', 'Supervisor Whatsapp'],
        },
      },
      {
        path: 'agents',
        name: 'agents',
        // redirect: { name: '' },
        component: () => import('@/views/AgentsView.vue'),
        meta: {
          breadcrumb: 'Equipo de trabajo',
          dashboardIcon: 'diversity_3',
          isNavItem: true,
          navText: 'Equipo de trabajo',
          allowedGroups: ['Supervisor Whatsapp'],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/recover-password',
    name: 'recoverPassword',
    component: () => import('@/views/RecoverPasswordSendEmail.vue'),
    meta: {
      isPublic: true,
    },
  },
  // {
  //   path: '/',
  //   name: 'dashboard',
  //   component: () => import('@/views/ConceptTest.vue'),
  // },

  // 404
  // {
  //   path: '/:catchAll(.*)*',
  //   component: () => import('@/layouts/MainLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: '404',
  //       component: () => import('@/views/ErrorNotFound.vue'),
  //     },
  //   ],
  // },
]

export default routes
