import Vue from 'vue'
import App from './App.vue'
import router from './router'
import settings from './settings'
import vuetify from './plugins/vuetify'

import { createPinia, PiniaVuePlugin } from 'pinia'
import { io } from 'socket.io-client'
import VuetifyToast from 'vuetify-toast-snackbar'
import moment from 'moment'
import prettyBytes from 'pretty-bytes'

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VuetifyToast)

const ioOptions = {
  autoConnect: true,
  token: () => ({ token: '123' }),
  extraHeaders: {
    'ngrok-skip-browser-warning': '1',
  },
}
Vue.prototype.$socket = io(settings.websocketServerUrl, ioOptions)

// Global filters
moment.locale('es')
Vue.filter('moment', function (value, format) {
  if (!value) return ''
  if (format === 'from') return moment(value).fromNow()
  return moment(value).format(format)
})
Vue.filter('prettyBytes', prettyBytes)

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app')
