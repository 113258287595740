import { defineStore } from 'pinia'
import axios from '@/plugins/axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
  }),

  getters: {
    hasGroup: (state) => (group) => state.user.groups.includes(group),
    hasAnyGroup: (state) =>
      function (groups) {
        return groups.some((g) => this.hasGroup(g))
      },
  },

  actions: {
    getHeaders() {
      const user = JSON.parse(localStorage.getItem('user'))
      const token = user?.key
      return token ? { Authorization: `Token ${token}` } : {}
    },

    restoreUserFromStorage() {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user?.email) return
      this.user = user
    },

    setUser(user) {
      this.user = user
      localStorage.clear()
      if (!user) return
      localStorage.setItem('user', JSON.stringify(user))
    },

    async login(email, password) {
      const data = { username: email, email, password }
      return axios
        .post('operation/admin/login', data)
        .then((res) => {
          this.setUser(res.data)
          return res
        })
        .catch((err) => err.response)
    },

    async sendRecoveryEmail(email) {
      return axios
        .post('/operation/admin/send-email-recover-password', { email })
        .then((res) => res)
        .catch((err) => err.response)
    },

    async changePassword(data) {
      return axios
        .post('/rest-auth/password/reset/confirm/', data)
        .then((res) => res)
        .catch((err) => err.response)
    },

    /*
export const sendEmailRecoverPassword = (_, { email }) => {
  return axios
    .post('/operation/admin/send-email-recover-password', { email })
    .then(
      (response) => {
        return response
      },
      (error) =>  {
        console.error(error)
        return error.response
      }
    )
}

export const changePassword = (_, data) => {
  return axios
    .post('/rest-auth/password/reset/confirm/', data)
    .then(
      (response) => {
        return response
      },
      (error) =>  {
        console.error(error)
        return error.response
      }
    )
}
    */

    async logout() {
      this.setUser(null)
    },
  },
})
